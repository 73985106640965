import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // BootstrapのCSSをインポート
import { Navbar, Nav, Container, Image, Button } from 'react-bootstrap';
import MainRouter from './routers/MainRouter';  // 服務條款隱私權路由組件
import CommonNavbar from './components/CommonNavbar';

const App = () => {
    return (
        <> 
            <MainRouter />

            <footer class="border-top footer text-muted">
                <div id="mailtous" class="container">
                    <div class="container small ">
                        <div class="row">

                            <div class="col-12 col-lg-6 col-xl-4 border-end p-5">
                                <img class="bi bi-layers-half top-0" src="/img/logo2.png" alt="Nav logo" />

                                <h3 class="h6 mb-3">Joyworks Medical Technology <br /> Co., Ltd.</h3>

                                Copyright © 2024 <a href="#"></a><br />
                                <a target="_blank" class="link-primary" href="/service-guide" alt="使用規範與指南">使用規範與指南</a>
                                 / 
                                <a target="_blank" class="link-primary" href="/privacy-policy" alt="隱私權政策">隱私權政策</a>

                            </div>

                            <div class="col-12 col-lg-6 col-xl-4 border-end p-5">
                                <h3 class="h6 mb-3">聯絡資訊:</h3>
                                <address class="text-secondary mt-3">

                                    No. 37, Sec. 3, Minquan E. Rd., Zhongshan<br />
                                    Dist., Taipei 104 Taiwan R.O.C.<br />
                                    <abbr class="bi-envelope me-2" title="Phone">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                        </svg>
                                    </abbr>

                                    02-77096608 #500
                                </address>
                                <address></address>

                            </div>

                            <div class="col-12 col-lg-6 col-xl-4 border-end p-5">
                                <h3 class="h6 mb-3">資訊連結:</h3>

                                <div class="container">
                                    <div class="row">
                                        <div class="col-12 col-lg-4 col-xl-4 text-center">
                                            <a href=" mailto:adm@yourjoyworks.onmicrosoft.com" class="nav-link link-secondary ps-0" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" class="bi bi-mailbox" viewBox="0 0 16 16">
                                                    <path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z" />
                                                    <path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z" />
                                                </svg>

                                            </a>
                                            Email
                                        </div>

                                        <div class="col-12 col-lg-4 col-xl-4 text-center">
                                            <a href="https://www.facebook.com/MIBYYourMindBodyGuide?locale=zh_TW" class="nav-link link-secondary ps-0" target="_blank" rel="noreferrer" alt="Facebook Link">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" class="link-primary bi bi-facebook" viewBox="0 0 16 16">
                                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                                </svg>
                                            </a>
                                            FaceBook
                                        </div>

                                        <div class="col-12 col-lg-4 col-xl-4 text-center">
                                            <a href="https://www.instagram.com/miby_joyworks/?igsh=ZDE1MWVjZGVmZQ%3D%3D" class="nav-link link-secondary ps-0" target="_blank" rel="noreferrer" alt="Instagram Link">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" class="link-danger bi bi-instagram"  viewBox="0 0 16 16">
                                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                                </svg>
                                            </a>
                                            Instagram
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </footer>

        </>
    );
};

export default App;
