import React from 'react';
import CustomNavbar from './CustomNavbar';

const PrivacyPolicy = () => {
    return (

        <>

            <CustomNavbar />

            <section id="numbers" className="bg-light section-padding">
                <div className="container">

                    <h1>隱私權政策</h1>

                    <p>歡迎使用由喜業科技股份有限公司（下稱「本公司」）開發的聊天機器人（下稱「本機器人」）。使用本機器人之使用者（下稱「使用者」），我們瞭解使用者委託給我們的個人資料極為重要，並認為妥善管理、保護及處理使用者的個人資料是我們的責任。我們謹此向使用者說明本機器人蒐集個人資料之目的、類別、利用範圍及方式、以及使用者所得行使之權利等事項；如果使用者對於本機器人的隱私權政策、以下相關告知事項、或與個人資料保護有關之相關事項有任何疑問，可以和我們聯絡，我們將儘快回覆說明。關於本隱私權政策，我們將在本頁面上更新相應的政策。請定期查看此頁面以取得最新的隱私權政策。</p>

                    <h2>第一條 適用範圍</h2>

                    <p>一、 當使用者使用本機器人所提供之任一服務或與之互動時，代表使用者同意我們根據本隱私權政策所述內容，對使用者的個人資料進行蒐集、處理及利用。</p>

                    <p>二、 無論使用者所居住的國家、地區為何，使用者都同意授權我們根據本隱私權政策在使用者所居住國家、地區以外之處蒐集、傳輸、處理、儲存和使用使用者的個人資料，以便向使用者提供服務。</p>

                    <p>三、 本機器人可能會處理與位於歐盟境內之個人相關資料，並可能透過遵循相關法規所訂之機制從歐盟境內傳輸該等資訊，相關法規所訂之機制包括依歐盟標準契約條款制定的資料處理協議。當使用者使用本機器人的服務，即表示使用者同意我們將與使用者相關的資訊傳輸到這些國家、地區。</p>

                    <p>四、 本機器人與行動應用程式內可能包含其他網站連結，關於該等連結網站的隱私權政策及與個人資料保護有關之告知事項，請參閱各該連結網站。</p>

                    <h2>第二條 個人資料蒐集、處理及利用之合法依據</h2>

                    <p>一、 我們擁有使用者的個人資料，是由向本機器人及其相應行動應用程式註冊會員或進行對話之使用者所提供。</p>

                    <p>二、 我們擁有使用者之個人資料均依照個人資料保護法之規定進行蒐集、處理及利用：</p>

                    <p>(一) 使用者註冊本機器人時，透過使用者同意提供之資料。</p>

                    <p>(二) 具法律明文規定，要求我們進行蒐集、處理或利用。</p>

                    <p>(三) 使用者自行公開或已合法公開之資料，惟我們不主動進行資料之蒐集、處理或利用。</p>

                    <p>(四) 為增進公共利益，我們僅於維護資訊安全、防止詐欺等合法利益下進行必要及有限之資料處理。</p>

                    <p>(五) 對使用者的權益無侵害者，我們僅於核實本機器人資料蒐集目的下，進行必要及合理之處置。</p>

                    <p>三、 本機器人並不提供醫療服務，包括診斷，治療，諮商等，故除個人資料保護法第6條之例外情形，我們不蒐集、處理或利用個人資料保護法第6條所指之各項資料。</p>

                    <p>四、 我們不希望未滿13歲之兒童向本機器人提供任何個人資料。如果您是父母或監護人，且您相信您的孩子可能已向我們提供了個人資料，請與我們聯繫，以便我們能夠採取必要的步驟來刪除相關資料。</p>

                    <h2>第三條 個人資料蒐集之方式及蒐集種類</h2>

                    <p>一、 我們可能會在下列時候蒐集使用者之個人資料：</p>

                    <p>(一) 當使用者註冊或使用本機器人時；</p>

                    <p>(二) 當使用者簽定協議，或是針對使用者與本機器人的互動提供其他文件或資訊時；</p>

                    <p>(三) 當使用者透過本機器人的應用程式或使用本機器人上的服務與我們互動時。這包括但不限於透過 Cookie (可能會在使用者與本機器人的應用程式互動時部署)；</p>

                    <p>(四) 當使用者在行動裝置上允許分享資訊給本機器人的應用程式；</p>

                    <p>(五) 當使用者提供意見或投訴給本機器人時；</p>

                    <p>(六) 當使用者基於任何原因提交個人資料給本機器人時。</p>

                    <p>二、 上述情形並非包含全部情形，僅列出可能會蒐集使用者個人資料的一些常見實例。</p>

                    <p>三、 本公司會依據本機器人各通路提供之服務，請使用者提供必要之作業資料，若使用者不提供相關資料，或提供之資料不正確，將影響本公司之服務品質：</p>

                    <p>(一) 本機器人會請使用者提供基本資料：包含使用者提供之性別、出生年月日、電子郵件信箱及其他使用者主動提供之資料。</p>

                    <h2>第四條 個人資料之處理或利用</h2>

                    <p>一、 本機器人僅會為作業之必要(如與使用者進行連貫之對話、提供使用者適當之資訊等)，處理、利用使用者的個人資料：</p>

                    <p>二、 在與本機器人進行對話時提供的任何資訊都可能與本公司或開發商進行共享，以改進模型的訓練和提升服務品質。然而，在這些共享中，我們會採取措施來最小化識別個人的風險。</p>

                    <p>三、 利用期間：我們將依本隱私權政策所載之個人資料蒐集的特定目的，於本機器人存續期間、法令所定應保存之期間內（包括但不限於民法、個人資料保護法等）及因執行業務所須之保存期間內（較法令所定期間更長者），持續保管、處理及利用所蒐集之個人資料。</p>

                    <p>四、 利用地區：除法令另有規定外，我們僅會基於使用者授權的範圍，於本機器人服務所提供之領域及地區內為作業之必要，處理、利用使用者所提供的個人資料。</p>

                    <p>五、 除另有法令規範或取得使用者同意以外，我們不會向第三人揭露使用者的個人資料，且除與使用者的權利有重大影響、保護公共利益，否則我們不會主動聯繫使用者。</p>

                    <p>六、 除非經過您同意，我們不會將您的個人資料販售，轉移或提供給任何第三方。但若為提供我們的服務，或於法律規定下，則不在此限。</p>

                    <h2>第五條 儲存保管安全</h2>

                    <p>一、 我們將採取合理的安全措施來保護您提供給我們的資料，以防止未經授權的存取、使用或洩漏。然而，請理解，沒有任何資料傳輸或儲存方法能夠保證100％的安全性。</p>

                    <p>二、 此外，為了提供更適合使用者需要的服務，本機器人會接收並且記錄使用者瀏覽器上的伺服器數值，包括IP位址、Cookie。</p>

                    <h2>第六條 個人資料的權利行使</h2>

                    <p>一、 使用者依個人資料保護法第3條規定，除非有其他法令限制，就使用者所提供之個人資料享有查詢或請求閱覽、請求製給複製本、請求補充或更正、請求停止蒐集處理或利用(包含撤回同意、反對我們繼續處理使用者的資料)、請求刪除的權利。</p>

                    <p>二、 如果使用者欲行使上述權利或是有其他諮詢、投訴事項，可以向我們提出意見，我們將盡快回覆使用者的問題。</p>

                    <p>三、 我們受理使用者行使各項個人權利，若為查詢或請求閱覽、請求製給複製本，將於提出請求之15日內回覆，必要時得再延長15日，我們將以書面通知使用者延長原因；若為請求補充或更正、請求停止蒐集處理或利用、請求刪除，我們將於提出請求之30日內回覆，必要時得再延長30日，我們將以書面通知使用者延長原因。</p>

                    <h2>第七條 Cookie</h2>

                    <p>為了便利使用者，本機器人可能使用cookie技術，以便於提供更適合使用者個人需要的服務；cookie是網站伺服器用來和使用者瀏覽器進行溝通的一種技術，它可能在使用者的電腦中儲存某些資訊，本機器人也會讀取儲存在使用者電腦中的cookie資料。使用者可以經由瀏覽器的設定，取消、或限制此項功能，但可能因此無法使用部份網站功能。若使用者想知道如何取消、或限制此項功能，可與我們聯絡，或參照使用者所使用的瀏覽器關於如何管理cookie的相關說明。</p>

                    <h2>第八條 個人資料正確性之權益影響</h2>

                    <p>依照各該服務之性質，為使相關服務得以順利提供、或使相關交易得以順利完成或履行完畢，若使用者不願意提供各該服務或交易所要求的相關個人資料予我們，並同意我們就該等個人資料依法令規定、以及本隱私權政策及其相關告知內容為相關之個人資料蒐集、處理、利用及國際傳輸，我們將尊重使用者的決定，但使用者可能因此無法使用本機器人。</p>

                    <h2>第九條 修訂之權利</h2>

                    <p>我們有權隨時修訂本隱私權政策及相關告知事項，並將在本頁面上更新相應的政策。請定期查看此頁面以取得最新的隱私權政策，我們將不另行個別通知。使用者並可以於此頁面詳閱修訂後的隱私權政策及相關告知事項。</p>

                    <h2>第十條 準據法及管轄法院</h2>

                    <p>因使用者使用本機器人與行動應用程式所造成之爭議，使用者同意均以中華民國法律為準據法。除法律另有強制規定者應依其規定外，並應以臺灣臺北地方法院為第一審管轄法院。</p>

                </div>
            </section>

        </>
    );
};

export default PrivacyPolicy;