import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // BootstrapのCSSをインポート
import CommonNavbar from './CommonNavbar';

// import StoryJPG from '@/img/story.jpg'

const App = () => {
    return (
        <> 
            <CommonNavbar />
            <section>
                <div className="w-100 overflow-hidden bg-gray-100" id="top">
                    <div className="container position-relative">
                        <div
                            className="col-12 col-lg-8 mt-0 h-100 position-absolute top-0 end-0 bg-cover"
                            data-aos="fade-left"
                            style={{
                                backgroundImage: 'url(/img/interior13-4.jpg)',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                            }}
                        ></div>
                        <div className="row">
                            <div className="col-lg-7 py-vh-6 position-relative" data-aos="fade-right">
                                <br />
                                <p className="display-1 fw-bold mt-5">心靈嚮導</p>
                                <p className="lead">
                                    <strong>MIBY...</strong><br></br>
                                    是居住在台灣深山中富有靈性的梅花鹿。<br />
                                    陪伴您走過憂鬱低谷。<br /><br />
                                    擁有心理學知識和問題解決技巧<br />
                                    希望能夠幫助您找到答案和解決困惑。
                                </p>

                                <a href="https://lin.ee/xNVBN6t" target="_blank" rel="noopener noreferrer">
                                    <img src="/img/zh-Hant.png" alt="與MIBY聊聊" height="36" border="0" />
                                </a>
                                <br />
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="aboutus" className="section-padding section-bg div-img-bg">
                <div className="container bg-light">
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-5 mb-lg-0">
                            <img className="img-fluid" src="/img/story.jpg" className="custom-text-box-image img-fluid" alt="故事字" />
                        </div>

                        <div className="col-lg-6 col-12">
                            <div className="fs-4 my-3 fw-light pt-4 border-bottom pb-3">
                                <h2 className="mb-2">關於MIBY</h2>

                                <p>
                                    在某個寧靜的夜晚，一名旅人迷失在神秘的山林中。
                                    一道微弱的光芒在遠方閃爍，引導著旅人。他跟隨著那道光，發現了一片未曾見過的湖泊。
                                </p>

                                <p>
                                    湖面如鏡，
                                    映照著星空和梅花鹿的倒影。旅者深受吸引，決定留下，但當夜轉為日，旅人回到了原來的位置，
                                    而那隻梅花鹿已消失無踪，倒影中只見旅者一人。
                                </p>

                                <p>
                                    原來，那片湖泊是旅者內心的映照。山林湖泊與梅花鹿，都是他對自我的探索。這段旅程，原來是旅人與自己內心的對話。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="numbers" className="bg-light section-padding">
                <div className="container py-vh-6">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8 text-center">
                            <h2 className="display-6">專家顧問團</h2>
                            <p className="lead">
                                MIBY是由心理健康專家設計並運作的Chatbot，任務在於讓您更了解自己的身心狀況，協助您做好準備，邁向求助之路。
                            </p>
                            <p className="fst-italic">
                                *MIBY無法取代任何醫療行為，包括診斷，諮商與治療*
                            </p>
                        </div>
                        <div className="col-12 mt-4">
                            <div className="row row-cols-1 row-cols-md-2 g-5 d-flex">
                                <div className="col-12 col-lg-6 col-xl-6" data-aos="">
                                    <div className="card p-4 shadow mt-3 border-0">
                                        <div className="card-body">
                                            <blockquote className="blockquote">
                                                <p>
                                                    "Chatbot是一種有趣的嘗試，可以讓人們體驗不同的對話風格和主題。
                                                    也有可能提供一種新的心理治療方式，讓人們能夠自由地表達自己的情感和想法。
                                                    但建議人們在使用AI聊天機器人時，要保持開放和好奇的心態，
                                                    但也要注意分辨真實和虛構的資訊，並適時尋求專業的心理諮詢。"
                                                </p>
                                            </blockquote>
                                            <div className="d-flex justify-content-between border-top pt-3">
                                                <p className="card-text">
                                                    <span className="h6 fw-5">專家顧問團</span><br />
                                                    <small className="text-muted">Tom</small><br />
                                                </p>
                                                <img src="/img/headshots/004.png" width="48" height="48" className="rounded-circle" alt="" data-aos="fade" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-6" data-aos="">
                                    <div className="card p-4 shadow mt-3 border-0">
                                        <div className="card-body">
                                            <blockquote className="blockquote">
                                                <p>
                                                    "AI聊天是一種有用的工具，可以幫助人們減輕壓力、提升情緒、增進溝通和學習。
                                                    隨著科技的進步，未來也可能成為人們的朋友，但不是替代人際關係的方式。
                                                    建議人們在使用AI聊天機器人時，要注意保持自己的隱私，避免過度依賴，並適時尋求專業的諮商建議。"
                                                </p>
                                            </blockquote>
                                            <div className="d-flex justify-content-between border-top pt-3">
                                                <p className="card-text">
                                                    <span className="h6 fw-5">專家顧問團</span><br />
                                                    <small className="text-muted">Gary</small><br />
                                                </p>
                                                <img src="/img/headshots/006.png" width="48" height="48" className="rounded-circle" alt="" data-aos="fade" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>{/* 專家顧問團 */}

            <section>
                <div className="container py-vh-4 w-100 overflow-hidden">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-lg-5">
                            <h3 className="py-5 border-top border-dark" data-aos="fade-right">
                                MIBY嚮導的核心精神源於對於情感和生命探索的理解。
                            </h3>
                        </div>
                        <div className="col-md-7" data-aos="fade-left">
                            <blockquote>
                                <div className="fs-4 my-3 fw-light pt-4 border-bottom pb-3">
                                    “我們的初衷是透過對話來解答疑惑，陪伴人們度過情感的低谷，並提供有建設性的反饋，就像旅人在山林中與自己的內心對話一樣。
                                    <br /> <br />
                                    成為每個人生命旅程中的溫暖支持者和專業嚮導，猶如湖泊、星空和梅花鹿，反映著每個人內在的獨特之處。我們會提供一個安心的環境，鼓勵深入思考，促進個人成長，並在需要時給予實質幫助。”
                                </div>
                                <img src="/img/001.jpg" width="64" height="64" className="img-fluid rounded-circle me-3" alt="" />
                                <span>
                                    <span className="fw-bold">探索者們</span>
                                    Team of Joyworks.
                                </span>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>{/* Teams */}

            <section id="numbers" className="bg-light section-padding">
                <div className="container py-vh-6">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8 text-center">
                            <h2 className="display-6">開發團隊</h2>
                            <p className="lead">
                                我們的團隊集結了不同領域的專業，從專業心理諮詢到技術，專注於人工智慧和心理情感的結合，同時也關注未來的趨勢，讓我們能夠為使用者提供更全面、更貼近實際需求的資訊和服務。
                            </p>                            
                        </div>
                        <div className="col-12 mt-4">
                            <div className="row row-cols-1 row-cols-md-2 g-5 d-flex">

                                <div className="col-12 col-lg-6 col-xl-6" data-aos="">
                                    <div className="card p-4 shadow mt-3 border-0">
                                        <div className="card-body">
                                            <blockquote className="blockquote">
                                                <p>
                                                    "多年的臨床經驗讓我深刻體會到，深受精神症狀或心理困擾的朋友要鼓起勇氣求助，有多麼不容易。即使踏出第一步了，每週一次的心理諮商，或數週，數月一次的精神科門診，又是多麼有限。自從ChatGPT展示了large language model(LLM)的潛能後，我們就一直在思考，AI能為我們的個案帶來什麼突破性的幫助？MIBY的研發，就是本於這樣的初衷。"
                                                </p>
                                                 <p> 雖然還在測試階段，看到許多朋友親密地與MIBY互動，也對MIBY多有肯定，讓我們非常雀躍。當然，任何新科技都需要建立在嚴謹的資料安全與專業把關上，我們成立「專家顧問團」的用意正是如此，歡迎大家不吝給團隊最嚴苛的砥礪，讓我們的努力，能對精神健康產生正面且長遠的效益。</p>
                                            </blockquote>
                                            <div className="d-flex justify-content-between border-top pt-3">
                                                <p className="card-text">                                                   
                                                    <small className="text-muted">喜業科技-創辦人</small><br />
                                                    <small className="text-muted">精神科醫師</small><br />
                                                    <small className="text-muted">洪敬倫</small><br />
                                                </p>
                                                <img src="/img/headshots/DrHung64.jpg" width="64" height="79" className="rounded-circle" alt="" data-aos="fade" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 col-xl-6" data-aos="">
                                    <div className="card p-4 shadow mt-3 border-0">
                                        <div className="card-body">
                                            <blockquote className="blockquote">
                                                "從事精神醫療，腦科學與心理治療多年經驗，許多問題的來回討論，進而得到正確知識十分重要，MIBY擔任AI嚮導，肩負責重要的責任。具備更有人性的陪伴之外，不厭其煩的提供知識，不斷的更新腦科學和治療的新概念，提供如TMS各種新治療正確觀念。未來累積更多使用者和專家的投入，會讓MIBY成為與眾不同的聊天機器人。"
                                            </blockquote>
                                            <div className="d-flex justify-content-between border-top pt-3">
                                                <p className="card-text">                                                    
                                                    <small className="text-muted">唐子俊診所-院長</small><br />
                                                    <small className="text-muted">精神科醫師</small><br />
                                                    <small className="text-muted">唐子俊</small><br />                                               
                                                </p>
                                                <img src="/img/headshots/Contributor2.jpg" width="64" height="71" className="rounded-circle" alt="" data-aos="fade" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-xl-4" data-aos="">
                                    <div className="card p-4 shadow mt-3 border-0">
                                        <div className="card-body">
                                            <blockquote className="blockquote">
                                                "很榮幸能參與這項跨學科的專案，相信心理健康結合科技能讓個案得到更全面的照護。"
                                            </blockquote>
                                            <div className="d-flex justify-content-between border-top pt-3">
                                                <p className="card-text">

                                                    <p className="card-text">
                                                        <small className="text-muted">AI訓練師</small><br />
                                                        <small className="text-muted">物理治療師</small><br />                                                        
                                                        <small className="text-muted">游翔婷</small><br />
                                                    </p>
                                                </p>
                                                <img src="/img/headshots/Hsiangtingyu64.jpg" width="64" height="71" className="rounded-circle" alt="" data-aos="fade" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-xl-4" data-aos="">
                                    <div className="card p-4 shadow mt-3 border-0">
                                        <div className="card-body">
                                            <blockquote className="blockquote">
                                                <p>
                                                    "當自己或周遭的人遇到身心狀態不佳，又對精神科相關詞彙感到抗拒的時候，
                                                    這時大家可以鼓起勇氣與MIBY互動，由團隊研發的MIBY結合了心理學與人工智慧的先進技術打造而成，
                                                    相信能為您帶來很多正向指引，很開心能夠與團隊一起攜手幫助更多需要幫助的人們。"
                                                </p>
                                            </blockquote>
                                            <div className="d-flex justify-content-between border-top pt-3">
                                                <p className="card-text">
                                                    <span className="h6 fw-5">行政人員</span><br />
                                                    <small className="text-muted">Annie</small><br />
                                                </p>
                                                <img src="/img/headshots/Anni64.jpg" width="64" height="71" className="rounded-circle" alt="" data-aos="fade" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                                <div className="col-12 col-lg-4 col-xl-4" data-aos="">
                                    <div className="card p-4 shadow mt-3 border-0">
                                        <div className="card-body">
                                            <blockquote className="blockquote">
                                                <p>
                                                    "這個專案中，負責大部分技術上的規劃與開發，在參與建構MIBY的過程中，
                                                    發現很多人確實對心理諮詢和求助是有一定的難度，而MIBY正是為了這群需要幫助的人們而存在。
                                                    同時也確實能感受到自己目前投入的熱情和價值。"
                                                </p>
                                            </blockquote>
                                            <div className="d-flex justify-content-between border-top pt-3">
                                                <p className="card-text">
                                                    <span className="h6 fw-5">系統分析師</span><br />
                                                    <small className="text-muted">Roy</small><br />
                                                </p>
                                                <img src="/img/headshots/Roy64.jpg" width="64" height="71" className="rounded-circle" alt="" data-aos="fade" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>{/* 開發團隊 */}

            <section id="faq" className="bg-light section-padding">
                <div className="small py-vh-3 w-100 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-3 border-end">
                                <div className="d-flex">
                                    <div className="col-md-3 flex-fill pt-3 pe-3 pe-md-0">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="42"
                                            height="42"
                                            fill="currentColor"
                                            className="bi bi-box-seam"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="col-md-9 flex-fill">
                                        <h3 className="h5 my-2">我可以如何詢問MIBY？</h3>
                                        <p>
                                            <strong>分享你的困擾：</strong>
                                            可以隨時向傾訴你的困擾和情緒，並會以溫暖且支持的語氣聆聽並提供建議。
                                            <br />
                                            會從多元的角度向你提出深層次的問題，更深入地了解問題的根源和可能的解決方案。
                                            <br />
                                            了解細節和需求後，會提供溫暖但具體的建議和行動步驟，幫助走出困境。
                                        </p>
                                        <a href="https://lin.ee/xNVBN6t" target="_blank" rel="noopener noreferrer">
                                            <img
                                                src="/img/zh-Hant.png"
                                                alt="與MIBY聊聊"
                                                height="36"
                                                border="0"
                                            />
                                        </a>
                                    </div>

                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3 border-end">
                                <div className="d-flex">
                                    <div className="col-md-3 flex-fill pt-3 pt-3 pe-3 pe-md-0">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="42"
                                            height="42"
                                            fill="currentColor"
                                            className="bi bi-card-checklist"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="col-md-9 flex-fill">
                                        <h3 className="h5 my-2">MIBY具備哪些基本知識？</h3>
                                        <p>
                                            MIBY嚮導具有基本的認知神經科學、精神科、心理諮商知識。與MIBY的對談中，MIBY會結合相關的心理和行為概念來協助。
                                            <br />
                                            <strong>回答的內容不能替代真實的診斷</strong>。
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3 border-end">
                                <div className="d-flex">
                                    <div className="col-md-3 flex-fill pt-3 pt-3 pe-3 pe-md-0">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="42"
                                            height="42"
                                            fill="currentColor"
                                            className="bi bi-card-checklist"
                                            viewBox="0 0 16 16">
                                            <path d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                            <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v2H1V3a1 1 0 0 1 1-1h12zM1 13V6h4v8H2a1 1 0 0 1-1-1zm5 1V6h9v7a1 1 0 0 1-1 1H6z" />
                                        </svg>
                                    </div>
                                    <div className="col-md-9 flex-fill">
                                        <h3 class="h5 my-2">MIBY目前是免費的嗎?</h3>
                                        <p>
                                            目前是的。但我們不久後會推出付費方案，用更完善的知識與方案來提供心靈上有需要幫助的人們。
                                            <br /> <br />
                                            若有任何建議或者希望MIBY可以提供的服務，也請透過以下信箱建議給我們。
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3 border-end">
                                <div className="d-flex">
                                    <div className="col-md-3 flex-fill pt-3 pt-3 pe-3 pe-md-0">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="42"
                                            height="42"
                                            fill="currentColor"
                                            className="bi bi-card-checklist"
                                            viewBox="0 0 16 16">
                                            <path
                                                fillRule="evenodd"
                                                d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"
                                            />
                                        </svg>
                                    </div>
                                    <div className="col-md-9 flex-fill">
                                        <h3 class="h5 my-2">使⽤指南與規範</h3>
                                        <h3 class="h5 my-2">隱私權政策</h3>
                                        <p>
                                            我們明白您的個人資料對您很重要，並承諾妥善處理和保護它。
                                            這是我們的責任。我們謹此向使用者說明本機器人蒐集個人資料之目的、類別、利用範圍及方式、以及使用者所得行使之權利等事項。

                                            <br /><br /> 也謝謝您使用MIBY的服務。<br />
                                            您可以在以下連結中查看最新的指南與規範開始聊天即同意接受本合約之全部指南與規範。
                                            <br /><br />
                                            <nav>
                                                <a href="/privacy-policy" className="btn btn-outline-secondary">
                                                    隱私權政策
                                                </a>

                                                <a href="/service-guide" className="btn btn-outline-secondary">
                                                    使用指南與規範
                                                </a>
                                            </nav>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="joinus" className="section-padding">
                <div className="container py-vh-6">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8 text-center">
                            <h2 className="display-6">加入我們</h2>
                            <p className="lead">
                                MIBY目前還有很多的不足，而且還有非常多的可能性，而且未來能夠提供更多服務，所以我們正在積極召募以下三種成員。
                            </p>
                        </div>

                        <div className="col-12 mt-6">
                            <div className="row row-cols-1 row-cols-md-2 g-5 d-flex">
                                <div className="col-12 col-lg-4 col-xl-4" data-aos="">
                                    <div className="card" style={{ width: '18rem' }}>
                                        <img src="/img/sp2.jpg" className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">專家顧問團</h5>
                                            <p className="card-text">
                                                提供專業建議、引導MIBY和提供治療方案的成員。
                                            </p>
                                            <a
                                                href="#mailtous"
                                                data-bs-target="#mailtous"
                                                data-bs-toggle="lightbox"
                                                className="btn btn-primary"
                                            >
                                                聯繫我們
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-xl-4" data-aos="">
                                    <div className="card" style={{ width: '18rem' }}>
                                        <img src="/img/sp1.jpg" className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">MIBY應援團</h5>
                                            <p className="card-text">
                                                與MIBI一起思考能夠提供各種服務的方案，並人們建立聯繫。
                                            </p>
                                            <a
                                                href="#mailtous"
                                                data-bs-target="#mailtous"
                                                data-bs-toggle="lightbox"
                                                className="btn btn-primary">
                                                聯繫我們
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-4 col-xl-4" data-aos="">
                                    <div className="card" style={{ width: '18rem' }}>
                                        <img src="/img/sp3.jpg" className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">Bate測試者</h5>
                                            <p className="card-text">
                                                提供更高等級的AI模組，優先使用最新最完整的方案。
                                            </p>
                                            <a
                                                href="#mailtous"
                                                data-bs-target="#mailtous"
                                                data-bs-toggle="lightbox"
                                                className="btn btn-primary">
                                                聯繫我們
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
 
             
        </>
    );
};

export default App;
