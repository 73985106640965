import React from 'react';
import CustomNavbar from './CustomNavbar';

const ServiceGuidePage = () => {
    return (
        <> 
            <CustomNavbar />

        <section id="numbers" className="bg-light section-padding">
            <div className="container">

                <h1>使用指南與規範</h1>

                <p>歡迎使用聊天機器人(下稱本機器人)！以下是聊天機器人之使用指南與規範(下稱本指南規範)，請您(下稱使用者)於使用聊天機器人前詳閱本指南規範，您於安裝、註冊、開始聊天機器人時，即表示同意接受本合約之全部條款。若您不同意本指南規範之任一部分，應立即刪除及停止使用聊天機器人：</p>

                <h2>第一條 使用規範</h2>

                <p>一、本機器人主要基於「文本生成」技術，根據使用者輸入之資料，產出相應之回答或供使用者進一步發問或選擇回答選項續生對話。本機器人之數據僅止於有關身心精神訊息，並僅截止於2022年01月，請謹記使用本機器人時，它極可能無法提供超出上開數據範圍之任何訊息。</p>

                <p>二、本機器人服務僅為蒐集使用者提供之資訊，經資料庫之數據比對及電腦分析，形成對使用者問題之回答，若經本機器人依資料庫歸納使用者可能有心理諮商之需求，將依使用者提供之所在位置列舉醫療單位供參考，請使用者自行斟酌並決定是否就診諮詢。但請注意，本機器人不會給予使用者身心狀態之評估分析，也不會提出治療計畫，不具任何診斷、治療或諮商之目的或功能。</p>

                <p>三、當使用者於使用本機器人時請務必遵守下述規範：</p>
                <ul>
                    <li>尊重他人：請在使用本機器人時尊重他人，避免使用冒犯性、歧視性或攻擊性的語言。遵循基本的禮貌和友好溝通，以確保良好的對話環境。</li>
                    <li>不要分享個人敏感信息：請不要在本機器人中分享個人身份證明、銀行帳戶資訊、密碼或其他敏感個人資料。本機器人不會要求此等訊息，請使用者謹慎對待在任何對話中提供之資訊。</li>
                    <li>適度使用：本機器人是一個工具，使用者應適度使用。請不要將本機器人用於自動化生成垃圾信息、不斷重複相同內容、詢問超過本機器人數據範圍或其他可能對其他人造成干擾或困擾的行為。</li>
                    <li>注意隱私保護：使用者提供之個人資訊或其與本機器人之對話內容可能會被記錄下來以改進模型性能，但我們將致力於保護該等數據之隱私，並請參閱隱私權政策。</li>
                </ul>

                <p>四、我們已採取措施來減少本機器人生成具有危害性之內容。若使用者遇有任何問題或擔憂，請及時聯絡我們，以幫助改進系統之安全性。</p>

                <p>五、使用者應確保所提供之各項資料均為正確，並同意喜業公司得基於優化本機器人、業務需求目的或其他法令許可之範圍內蒐集、處理或利用使用者之個人資料，並請使用者參閱隱私權政策。</p>

                <h2>第二條 禁止事項</h2>

                <p>一、使用者需滿18歲，若未滿18歲之少年，請於父母或監護人陪同下使用。此外，我們不希望未滿13歲之兒童向本機器人提供任何個人資料，如果您是父母或監護人，且您相信您的孩子可能已向我們提供了個人資料，請與我們聯繫，以便我們能夠採取必要的步驟來刪除相關資料。</p>

                <p>二、本機器人之數據僅止於有關身心精神訊息，請勿要求本機器人進行其他之對話，本機器人極可能無法提供任何訊息。</p>

                <p>三、本機器人之回答僅供使用者個人參考，請勿將本機器人作為心理諮商、心理治療或身心狀況評估之依據，亦不要以之取代專業醫療人員之意見，同時禁止作為使用者營利、教學示範、展示等用途。</p>

                <h2>第三條 所有權及智慧財產權</h2>

                <p>本機器人之所有權利均歸喜業科技股份有限公司(下稱喜業公司)所有，並受中華民國智慧財產權相關法規、國際著作權條約以及其他智慧財產權之法律及國際間著作權相互保障條約等之保護。</p>

                <h2>第四條 免責聲明</h2>

                <p>一、本機器人為由喜業公司開發之語言模型，旨在依使用者提供之資訊給予回答，讓使用者瞭解是否有心理諮商需求之可能，儘管本機器人經過廣泛的訓練及測試，但本機器人仍不能保證提供之資訊之準確性、完整性或適用性，且本機器人可能受使用者提供資訊正確度、心理素質、配合程度、個人認知或其他不確定因素影響，我們對於使用者使用本機器人之服務不負具有任何背書或保證之聲明。</p>

                <p>二、本機器人不具情感、主觀意識或判斷能力，請謹慎對待本機器人提供之資訊，並請瞭解本機器人提供之資訊僅供參考，對使用者並無規制或強制力，亦不具任何暗示或保證效用，使用者應依個人思考謹慎判斷為決策，並務必諮詢相應領域之專業人士以獲得準確的建議。任何經過與本機器人交流而做出之決策均由使用者自行承擔風險。對於依賴於本機器人提供的資訊所導致之任何損失或損害，喜業公司及本機器人本身均不承擔任何責任。</p>

                <p>三、本機器人在使用過程可能因網路、程式更新等因素有傳輸中斷、對話中斷或發生故障等無法使用時，不論其原因為何，使用者均同意不得據此向喜業公司、本機器人或任何人為賠償之請求。</p>

                <p>四、由於本機器人之開發是持續性的，喜業公司將繼續加強功能，以精進本機器人。因此喜業公司可能對本機器人之功能做各種變更，且無法事先告知。</p>

                <h2>第五條 準據法及管轄法院</h2>

                <p>因使用者使用本機器人與行動應用程式所造成之爭議，使用者同意均以中華民國法律為準據法。除法律另有強制規定者應依其規定外，並應以臺灣臺北地方法院為第一審管轄法院。</p>

            </div>
            </section>

       </>
    );
}

export default ServiceGuidePage;