import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from '../App';
import PrivacyPolicySection from '../components/PrivacyPolicySection';
import ServiceGuidePage from '../components/ServiceGuidePage';
import HomePage from '../components/HomePage';

function MainRouter() {
    return (
       <Router>
            <Routes >
                <Route path="/" element={<HomePage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicySection />} />
                <Route path="/service-guide" element={<ServiceGuidePage />} />
        </Routes >
       </Router>
        
    );
}

export default MainRouter;