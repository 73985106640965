import React from 'react';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';

const CommonNavbar = () => {
    return (
        <Navbar bg="light" expand="lg" fixed="top" className="navbar navbar-expand-lg navbar-light bg-white border-bottom box-shadow fixed-top" tabIndex="0">
            <Container>
                
                <Navbar.Brand href="/">
                    <Image src="/img/logo3.png" height="30" className="bi bi-layers-half top-0" alt="Logo" />
                    <span className="ms-1 fw-bolder">MIBY</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarSupportedContent" />
                <Navbar.Collapse id="navbarSupportedContent">
                    <Nav className="me-auto mb-2 mb-lg-0">
                        <Nav.Link href="#aboutus">關於</Nav.Link>
                        <Nav.Link href="#numbers">專家成員</Nav.Link>
                        <Nav.Link href="#faq">常見問題</Nav.Link>
                        <Nav.Link href="#joinus">加入我們</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default CommonNavbar;